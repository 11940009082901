/* app.css */

.scrollbar-hidden::-webkit-scrollbar,
.categories-row::-webkit-scrollbar {
	display: none;
}

*::-webkit-scrollbar {
	height: 3.5px !important;
	width: 3px !important;
}

*::-webkit-scrollbar-track {
	background: 0 0 !important;
}

*::-webkit-scrollbar-thumb {
	background: -webkit-linear-gradient(#61c087, #f99d1c) !important;
	border-radius: 5px !important;
	border: none !important;
}

.modal:focus .scrollbar-hidden {
	overflow: hidden;
}

.filled {
	border: 1.5px solid #61c087;
	border-radius: 5px;
}

.empty {
	border: 1.5px solid #f99d1c;
	border-radius: 5px;
}

.btncolor {
	color: #61c087 !important;
	/* color: #f99d1c !important;
	color: #4baaad !important; */
}

.dark-modal-backdrop {
	background-color: rgba(0, 0, 0, 0.9) !important;
	backdrop-filter: blur(20px);
}

.app-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.custom_container {
	width: 100%;
	max-width: 1200px;
	margin: auto;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

body {
	background: #ffffff !important;
	/* background: #f5f5f5 !important; */
	/* background: #fcfcfb !important; */
	/* background: #fffefc !important; */
	/* background: #f7f7f7 !important; */
	/* background: linear-gradient(#f2f2f2, #e9e9e9) !important; */
	margin-top: -10px !important;
	margin-bottom: 10px !important;
}

.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity 0.5s ease; /* Add transition for smooth fade-in/fade-out */
	opacity: 1; /* Initially set opacity to 1 */
}

.loader {
	border: 8px solid #f3f3f3; /* Light grey */
	border-top: 8px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* Add classes for smooth content transition */
.custom_content {
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}

.blur {
	filter: blur(5px); /* Add blur effect */
}

.custom_content-loaded {
	opacity: 1;
}
