.custom_offcanvas {
	padding: 0.1px;
	border-radius: 10px;
}

.custom_select,
.custom_input {
	border-radius: 10px;
	border: 2px solid #86d2a47d;
	background-color: rgb(248, 254, 250);
	font-weight: 500;
	color: rgba(1, 7, 1, 0.882);
	padding: 4px 10px;
	outline: none;
	width: 100%;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.custom_input {
	border: 1px solid #ffd69d83;
	background-color: #fffdfba2;
	cursor: text;
}

.custom_btn {
	border: 1.5px solid #60ba786a;
	background-color: #aeffc32b;
	color: rgb(4, 14, 8);
	outline: none;
	font-weight: 600;
	padding: 4px 10px;
	border-radius: 10px;
}

.custom_btn:hover {
	box-shadow: 1px 1px 2px #61c0877d;
}

.custom_select:hover {
	box-shadow: 1px 1px 2px #61c0877d;
}

.custom_input:hover {
	box-shadow: 1px 1px 2px #f99d1c34;
}

.custom_form-group {
	flex-direction: column;
	align-items: flex-start;
	gap: 2px;
	width: 100%;
}

.custom_form-group label {
	font-weight: 600;
}

.filter-group-wrapper {
	width: 100%;
	flex-wrap: wrap;
	background-color: #cccccc22;
	padding: 5px;
	border-radius: 10px;
	transition: all 0.6s ease;
	gap: 2vh;
	position: relative;
}

.filter-group-wrapper::before {
	content: attr(data-info);
	position: absolute;
	top: 2px;
	right: 4px;
	width: max-content;
	text-align: center;
	padding: 5px 9px;
	background-color: #ffffff;
	border-radius: 5px;
	color: #020905d4;
}

.offcanvas-backdrop {
	background-color: rgba(0, 0, 0, 0.203) !important;
}

.offcanvas-backdrop.show {
	background-color: rgba(0, 0, 0, 0.203);
	opacity: 1;
	backdrop-filter: blur(5px);
}
