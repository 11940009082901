.custom_positioning-brand {
	justify-content: space-around !important;
}

@media (max-width: 991.5px) {
	.custom_positioning-left-wrapper {
		width: 100%;
	}
	.navbar-brand-box {
		width: unset !important;
	}
}

.header_dealo {
	position: sticky;
	top: 0px;
	z-index: 2;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 350ms;
	border-bottom: 1px solid rgba(224, 146, 30, 0.15);
	/* border-bottom: 1px solid rgba(97, 192, 135, 0.2); */
}

.hide {
	transform: translateY(-100%);
}
