.data-card {
	width: 180px;
	min-width: 180px;
	height: 130px;
	background-color: white;
	position: relative;
	overflow: hidden;
	border-radius: 15px;
	cursor: pointer;
}

.data-card img {
	max-width: 200%;
	vertical-align: middle;
	object-fit: cover;
	transition: all 0.3s ease;
}

.item-name {
	position: absolute;
	bottom: 0;
	margin-bottom: 0;
	color: white;
	width: 100%;
	height: 100%;
	box-shadow: rgba(0, 0, 0, 0.708) 0px -50px 36px -28px inset;
	font-weight: 600;
	padding-bottom: 5px;
}

.item-name p {
	margin-bottom: 2px;
}
.item-name .info-span {
	opacity: 0;
	transform: translateY(20px);
	font-size: 12px;
	gap: 5px;
	transition: all 0.3s ease;
}

.data-card:hover .item-name .info-span {
	opacity: 1;
	transform: translateY(0px);
	transition: all 0.3s ease;
}

.data-card:hover img {
	transform: scale(1.1);
	transition: all 0.3s ease;
}

.item-name .info-span svg {
	fill: orange;
}

.item-name .info-span p {
	margin-bottom: 0;
}

@media (max-width: 920px) {
	.item-name .info-span {
		opacity: 1;
		transform: translateY(0px);
	}
}

@media (max-width: 768px) {
	.data-card {
		width: 140px;
		min-width: 140px;
		height: 100px;
	}
}
