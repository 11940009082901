* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.slider-container::-webkit-scrollbar {
	display: none;
	scroll-snap-type: x mandatory;
}

.slider-container {
	overflow: auto;
	justify-content: flex-start;
	flex-wrap: nowrap;
	gap: 15px;
	transition: all 0.3s ease;
	position: relative;
}

.right-shadow {
	mask-image: linear-gradient(
		to right,
		black calc(100% - 40px),
		transparent 100%
	);
}

.left-shadow {
	mask-image: linear-gradient(
		to left,
		black calc(100% - 40px),
		transparent 100%
	);
}

.both-side-shadow {
	mask-image: linear-gradient(
		to right,
		transparent 0%,
		black 35px,
		black calc(100% - 35px),
		transparent 100%
	);
}
.slider-title {
	font-weight: 600;
	position: relative;
	justify-content: space-between;
}

.slider-title p,
h3 {
	margin-bottom: 0;
}

@media (max-width: 768px) {
	.slider-title h3 {
		font-size: 15px;
	}
	.scroll-span {
		display: none !important;
		font-size: 5px;
	}
}
