.fancyCard {
	border: 1.2px solid rgba(255, 162, 56, 0.385);
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.fancyCard:hover {
	box-shadow: 1px 1px 25px 10px rgba(248, 201, 146, 0.4);
	border: 1.2px solid rgb(255, 162, 56);
}

.fancyCard:before {
	content: "";
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		120deg,
		transparent,
		rgba(248, 241, 146, 0.4),
		transparent
	);

	transition: all 650ms ease;
}

.fancyCard:hover {
	transform: scale(1.002);
	transition: all 0.3s ease-in-out;
}

.fancyCard:hover:before {
	left: 100%;
}

.hot-post-gif {
	position: absolute;
	bottom: 3px;
	right: 3px;
	width: 20px;
	height: 25px;
	border-radius: 5px;
	overflow: hidden;
}
.hot-post-gif img {
	object-fit: contain;
	max-width: 90%;
	vertical-align: middle;
}
